var body = document.body,
    html = document.documentElement;

var height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );
let main = document.getElementById('main');
let footer = document.getElementById('footer');


if(height < 2000){
    main.style.zIndex = 5;
    main.style.position = 'static';
    main.style.backgroundColor = '#fff';
    main.style.marginBottom = '0';

    footer.style.position = 'static';
    footer.style.width = '100%'
    footer.style.bottom = 0;
    footer.style.zIndex = -1;
    footer.style.paddingTop =  "0px";
    footer.style.marginTop = '0px';
    footer.style.backgroundColor = '#000000';
}

